import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import cx from 'classnames';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { useGetChatsQuery, useGetChatsStatusQuery } from '@@/services/whatsapp';
import { useRestartMutation } from '@@/services/root';
import { Statuses } from '@@/constants/whatsapp';
import SectionTitle from '@@/components/SectionTitle';
import Loader from '@@/components/Loader';
import Message from '@@/components/Message';

const ConversationsPage: React.FC = () => {
  const { data } = useGetChatsStatusQuery();

  const { data: chats } = useGetChatsQuery(null, {
    skip: data?.status !== Statuses.READY,
  });

  const [restart, { isSuccess }] = useRestartMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Redémarrage en cours...');
    }
  }, [isSuccess]);

  const restartBtn = useMemo(() => {
    return (
      <button
        className="bg-cyan-400 p-2 rounded-lg h-fit mt-2"
        onClick={() => restart()}
      >
        <span className="text-white font-main text-sm">
          Reinitialiser la connexion
        </span>
      </button>
    );
  }, [restart]);

  if (!data) {
    return null;
  }

  if (!chats?.length && !data.qr && data.status === Statuses.LOADING) {
    return (
      <div className="flex flex-col items-center justify-center w-full mt-8">
        <div className="flex flex-row items-center justify-center">
          <Loader />
          <p className="ml-2 font-main text-main text-lg">Chargement...</p>
        </div>
        {restartBtn}
      </div>
    );
  }

  if (!chats?.length && data.status === Statuses.LOADING) {
    return (
      <>
        <QRCode value={data.qr} className="mt-8 mx-auto" />
        {restartBtn}
      </>
    );
  }

  if (!chats?.length) {
    return (
      <div className="flex flex-col">
        <div className="m-8 flex flex-row items-center justify-center w-full">
          <Loader />
          <p className="ml-2 font-main text-main text-lg">
            Chargement des conversations ...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 w-full h-full overflow-scroll">
      <SectionTitle title={`Chats (${chats.length})`} className="mb-6" />

      <ul className="grid grid-cols-1 md:grid-cols-3">
        {chats.map((chat, i) => {
          let bgColor = 'white';
          switch (chat.lastMessage?.fromMe) {
            case true:
              bgColor = 'bg-green-100';
              break;
            case false:
              bgColor = 'bg-red-100';
              break;
            default:
              break;
          }

          return (
            <Link
              key={i}
              to={`/conversations/${chat.id.server}/${chat.id.user}`}
              className={cx(
                'block z-10 m-2 p-2 rounded-lg border-gray-400 border h-44 overflow-hidden',
                bgColor,
              )}
            >
              <div className="flex flex-row items-center justify-between font-main text-main">
                <p className="font-bold">{chat.name}</p>
                {!!chat.timestamp && (
                  <p className="ml-8 italic text-sm">
                    {moment.unix(chat.timestamp).calendar()}
                  </p>
                )}
              </div>
              <Message message={chat.lastMessage} disableLinks />
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default ConversationsPage;
