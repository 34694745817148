import { api } from '@@/services/api';
import { convertToFormData } from '@@/utils/formData';

export type TaskInputType = Omit<
  Unpatient.Task,
  'patientId' | 'practitionerContactId'
> & {
  patientId: string;
  practitionerContactId: string;
};

export const taskApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<Unpatient.Task[], void>({
      query: () => `tasks`,
      providesTags: ['Tasks'],
    }),
    getTask: builder.query<Unpatient.Task, string>({
      query: (taskId) => `tasks/${taskId}`,
      providesTags: ['Tasks'],
    }),
    createTask: builder.mutation<Unpatient.Task, TaskInputType>({
      query: (body) => {
        const formData = new FormData();
        return {
          url: `tasks`,
          method: 'POST',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Tasks'],
    }),
    patchTask: builder.mutation<Unpatient.Task, TaskInputType>({
      query: ({ id, ...body }) => {
        const formData = new FormData();

        return {
          url: `/tasks/${id}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Tasks'],
    }),
    deleteTask: builder.mutation<{}, string>({
      query: (id) => ({
        url: `tasks/${id}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Tasks'],
    }),
    deleteManyTasks: builder.mutation<{}, string[]>({
      query: (ids) => ({
        url: 'tasks',
        method: 'DELETE',
        body: { ids },
      }),
      invalidatesTags: ['Tasks'],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTaskQuery,
  useCreateTaskMutation,
  usePatchTaskMutation,
  useDeleteTaskMutation,
  useDeleteManyTasksMutation,
} = taskApi;
