import React, { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import { MdOutlineCreditCard, MdOutlineCreditCardOff } from 'react-icons/md';
import {
  LuMessageSquareDot,
  LuChevronRight,
  LuCalendarClock,
  LuSearch,
} from 'react-icons/lu';
import {
  useGetAllPatientsQuery,
  useSearchPatientsQuery,
} from '@@/services/user';
import { getAge } from '@@/utils/date';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Statuses } from '@@/constants/user';
import { MIN_SEARCH_LENGTH } from '@@/constants/search';
import StandardInput from '@@/components/Inputs/StandardInput';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import Loader from '@@/components/Loader';

const schema = yup
  .object({
    patientName: yup
      .string()
      .min(
        MIN_SEARCH_LENGTH,
        `Le nom doit contenir au moins ${MIN_SEARCH_LENGTH} caractères`,
      )
      .required("Veuillez entrer le nom d'un patient"),
  })
  .required();

enum Tabs {
  TODAY = 'today',
  FOLLOW = 'followed',
  REMINDER = 'reminder',
  INACTIVE = 'inactive',
  SEARCH = 'search',
}

const Tab: React.FC<{
  id: string;
  title: string;
  isActive: boolean;
  cb: Function;
}> = ({ id, isActive, title, cb }) => {
  return (
    <button
      onClick={() => {
        cb(id);
      }}
    >
      <span
        className={cx(
          'font-semibold text-sm',
          isActive ? 'text-sky-500' : 'text-slate-700',
        )}
      >
        {title}
      </span>
    </button>
  );
};

const PatientCard: React.FC<{ patient: Unpatient.User }> = ({ patient }) => {
  const phone = useMemo(() => patient?.wsPhone || patient?.phone, [patient]);

  const eventSection = useMemo(() => {
    switch (patient.events?.length) {
      case 0:
        return (
          <p className="font-main text-xs text-slate-700 border border-slate-200 p-1 rounded-md bg-slate-100 w-fit">
            Aucun événement à venir
          </p>
        );
      case 1:
        const event = patient.events[0];
        return (
          <p className="font-main text-xs text-slate-700 max-w-fit truncate border border-slate-200 p-1 rounded-md bg-slate-100">
            {event.name}
          </p>
        );
      default:
        const [firstEvent, ...rest] = patient.events as Unpatient.Event[];
        return (
          <div className="relative flex flex-row items-center">
            <p className="font-main text-xs text-slate-700 max-w-fit w-40 truncate border border-slate-200 p-1 rounded-md bg-slate-100">
              {firstEvent.name}
            </p>
            <p className="font-main text-xs text-slate-700 ml-3">
              +{rest.length} à venir
            </p>
          </div>
        );
    }
  }, [patient.events]);

  return (
    <Link
      key={patient.id}
      to={
        phone
          ? `/conversations/c.us/${phone}/${patient.id}`
          : `/patients/${patient.id}`
      }
      className={cx(
        'relative mb-2 mr-2 rounded-lg border border-slate-200 bg-white w-80',
      )}
    >
      <div className="flex flex-row items-center border-b border-slate-100 p-3">
        {patient.photoDocumentId ? (
          <AuthenticatedImage
            documentId={patient.photoDocumentId}
            placeholder="/user-profile-placeholder.jpg"
            alt={`${patient?.firstName} ${patient?.lastName}`}
            className="w-10 h-10 rounded-full"
          />
        ) : (
          <div className="w-10 h-10 font-main rounded-full bg-slate-100 border border-slate-200 flex items-center justify-center">
            <span className="text-sky-600 font-semibold">
              {patient?.firstName?.slice(0, 1)}
              {patient?.lastName?.slice(0, 1)}
            </span>
          </div>
        )}

        <div className="flex flex-col ml-2 font-main">
          <p className="flex flex-row text-slate-700">
            <span className="">{patient.firstName}</span>&nbsp;
            <span className="font-bold">{patient.lastName}</span>
            {patient.activeSubscription ? (
              <MdOutlineCreditCard className="text-emerald-500 ml-1" />
            ) : (
              <MdOutlineCreditCardOff className="text-red-600 ml-1" />
            )}
          </p>
          {patient.medicalRecord?.birthDate && (
            <p className="text-sm text-slate-500">
              {getAge(patient.medicalRecord.birthDate)} ans
            </p>
          )}

          {!!patient.lastMessage && !patient.lastMessage.fromMe && (
            <LuMessageSquareDot
              size={20}
              className="text-red-600 position absolute top-2 right-2"
            />
          )}
        </div>
      </div>

      <div className="p-3">{eventSection}</div>
    </Link>
  );
};

const PatientRow: React.FC<{ patient: Unpatient.User }> = ({ patient }) => {
  const phone = useMemo(() => patient?.wsPhone || patient?.phone, [patient]);

  return (
    <Link
      key={patient.id}
      to={
        phone
          ? `/conversations/c.us/${phone}/${patient.id}`
          : `/patients/${patient.id}`
      }
      className={cx('relative mr-2 py-3 border-b border-slate-100 w-full')}
    >
      <div className="flex flex-row items-center justify-between py-1.5">
        <div className="flex flex-row items-center">
          {patient.photoDocumentId ? (
            <AuthenticatedImage
              documentId={patient.photoDocumentId}
              placeholder="/user-profile-placeholder.jpg"
              alt={`${patient?.firstName} ${patient?.lastName}`}
              className="w-10 h-10 rounded-full"
            />
          ) : (
            <div className="w-10 h-10 font-main rounded-full bg-slate-100 border border-slate-200 flex items-center justify-center">
              <span className="text-sky-600 font-semibold">
                {patient?.firstName?.slice(0, 1)}
                {patient?.lastName?.slice(0, 1)}
              </span>
            </div>
          )}
          <div className="flex flex-col ml-2 font-main">
            <p className="flex flex-row text-slate-700">
              <span className="">{patient.firstName}</span>&nbsp;
              <span className="font-bold">{patient.lastName}</span>
              {patient.activeSubscription ? (
                <MdOutlineCreditCard className="text-emerald-500 ml-1" />
              ) : (
                <MdOutlineCreditCardOff className="text-red-600 ml-1" />
              )}
            </p>
            {patient.medicalRecord?.birthDate && (
              <p className="text-sm text-slate-500">
                {getAge(patient.medicalRecord.birthDate)} ans
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-row items-center">
          {!!patient.lastMessage && !patient.lastMessage.fromMe && (
            <LuMessageSquareDot size={20} className="text-red-600" />
          )}

          <p className=" ml-2 flex flex-row items-center">
            <LuCalendarClock size={20} className="text-gray-400" />
            <span className="ml-2 text-sm text-gray-700">
              {patient.events?.length ?? 0}
            </span>
          </p>

          <LuChevronRight size={16} className="text-gray-400 ml-2" />
        </div>
      </div>
    </Link>
  );
};

const PatientsPage: React.FC = () => {
  const { data: allPatients, isLoading: patientsAreLoading } =
    useGetAllPatientsQuery();

  const { register, watch, setValue } = useForm<{
    patientName: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      patientName: '',
    },
  });

  const patientName = watch('patientName');
  const { data: searchedPatients } = useSearchPatientsQuery(patientName, {
    skip: patientName?.length < MIN_SEARCH_LENGTH,
  });

  const [activeTab, setActiveTab] = useState(Tabs.TODAY);
  const safeSetActiveTab = useCallback(
    (tabId: Tabs) => {
      setActiveTab(tabId);
      setValue('patientName', '');
    },
    [setActiveTab],
  );
  const cutOffDate = useMemo(() => moment().subtract(10, 'days').unix(), []);

  const patients = useMemo(() => {
    if (patientName?.length >= MIN_SEARCH_LENGTH) {
      return searchedPatients;
    }

    switch (activeTab) {
      case Tabs.TODAY:
        return allPatients?.filter((p) => p.hasEventToday);
      case Tabs.FOLLOW:
        return allPatients?.filter((p) => {
          const hasSubscriptionAnddidExchangeMessagesRecently =
            !!p.activeSubscription &&
            p.lastMessage?.timestamp &&
            p.lastMessage.timestamp > cutOffDate;
          return (
            p.status === Statuses.ACTIVE &&
            (!!p.eventsCount ||
              hasSubscriptionAnddidExchangeMessagesRecently) &&
            !p.hasEventToday
          );
        });
      case Tabs.REMINDER:
        return allPatients?.filter(
          (p) => p.status === Statuses.ACTIVE && !p.activeSubscription,
        );
      case Tabs.INACTIVE:
        return allPatients?.filter((p) => p.status === Statuses.INACTIVE);
      case Tabs.SEARCH:
        return searchedPatients;
      default:
        return allPatients;
    }
  }, [patientName, searchedPatients, allPatients, activeTab, cutOffDate]);

  const patientsRequiringFollowUp = useMemo(() => {
    return (
      allPatients
        ?.filter((p) => {
          const didExchangeMessagesRecently =
            p.lastMessage?.timestamp && p.lastMessage.timestamp > cutOffDate;

          const didSpeakLast = !!p.lastMessage && !p.lastMessage.fromMe;

          return (
            p.status === Statuses.ACTIVE &&
            !!p.activeSubscription &&
            (didSpeakLast || (!p.eventsCount && !didExchangeMessagesRecently))
          );
        })
        .sort((p1, p2) => {
          const p1HasLastMessage = p1.lastMessage && !p1.lastMessage?.fromMe;
          const p2HasLastMessage = p2.lastMessage && !p2.lastMessage?.fromMe;

          if (p1HasLastMessage && !p2HasLastMessage) {
            return -1;
          }

          if (!p1HasLastMessage && p2HasLastMessage) {
            return 1;
          }

          return 0;
        }) ?? []
    );
  }, [allPatients, cutOffDate]);

  const title = useMemo(() => {
    switch (activeTab) {
      case Tabs.TODAY:
        return "Patient(s) ayant RDV aujourd'hui";
      case Tabs.FOLLOW:
        return 'Patient(s) suivis';
      case Tabs.REMINDER:
        return 'Patient(s) à relancer';
      case Tabs.INACTIVE:
        return 'Patient(s) inactifs';
      case Tabs.SEARCH:
        return 'Patient(s) recherché(s)';
      default:
        return '';
    }
  }, [activeTab]);

  const patientsBlock = useMemo(() => {
    if (patientsAreLoading) {
      return (
        <div className="m-8 flex flex-row items-center justify-center w-full">
          <Loader />
          <p className="ml-2 font-main text-main text-lg">Chargement...</p>
        </div>
      );
    }

    if (activeTab === Tabs.REMINDER) {
      return (
        <div className="">
          <div className="mt-4">
            <p className="text-slate-700 font-semibold mb-4">{title}</p>

            <p className="text-slate-700 mt-6 mb-2 pb-1 rounded-md border-b border-slate-200">
              Pour un abonnement
            </p>
            <div className="flex flex-row w-full flex-wrap">
              {patients
                ?.filter((p) => !!p.allEventsCount)
                .map((patient) => (
                  <PatientCard key={patient.id} patient={patient} />
                ))}
            </div>

            <p className="text-slate-700 mt-6 mb-2 pb-1 rounded-md border-b border-slate-200">
              <span className="">Pour un onboarding</span>
            </p>
            <div className="">
              {patients
                ?.filter((p) => !p.allEventsCount)
                ?.map((patient) => (
                  <PatientRow key={patient.id} patient={patient} />
                ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="">
        {!!patients?.length && (
          <div className="mt-4">
            <p className="text-slate-700 font-semibold mb-2">{title}</p>

            <div className="flex flex-row w-full flex-wrap">
              {patients.map((patient) => (
                <PatientCard key={patient.id} patient={patient} />
              ))}
            </div>
          </div>
        )}

        {!!patientsRequiringFollowUp?.length &&
          [Tabs.TODAY, Tabs.FOLLOW].includes(activeTab) && (
            <div className="mt-8">
              <p className="text-slate-700 font-semibold mb-2">
                Patients ayant besoin de suivi
              </p>
              <div className="flex flex-row w-full flex-wrap">
                {patientsRequiringFollowUp.map((patient) => (
                  <PatientRow key={patient.id} patient={patient} />
                ))}
              </div>
            </div>
          )}
      </div>
    );
  }, [patients, patientsRequiringFollowUp, patientsAreLoading, title]);

  return (
    <div className="w-full p-4 mt-0 h-full">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center h-18">
          <h2 className="text-slate-700 text-lg font-semibold">Patients</h2>
          <div className="flex flex-row items-center space-x-6 px-6 mx-6 border-x border-slate-200">
            <Tab
              id={Tabs.TODAY}
              title="Du jour"
              isActive={activeTab === Tabs.TODAY}
              cb={safeSetActiveTab}
            />
            <Tab
              id={Tabs.FOLLOW}
              title="Suivis"
              isActive={activeTab === Tabs.FOLLOW}
              cb={safeSetActiveTab}
            />
            <Tab
              id={Tabs.REMINDER}
              title="A relancer"
              isActive={activeTab === Tabs.REMINDER}
              cb={safeSetActiveTab}
            />
            <Tab
              id={Tabs.INACTIVE}
              title="Inactifs"
              isActive={activeTab === Tabs.INACTIVE}
              cb={safeSetActiveTab}
            />
          </div>

          <div className="w-52 md:w-64">
            <StandardInput
              register={register}
              id="patientName"
              placeholder="Recherche..."
              type="text"
              label={
                <div className="flex flex-row items-center">
                  <LuSearch size={12} className="text-sky-500" />
                  <span className="text-slate-400 ml-1">Recherche...</span>
                </div>
              }
              inputClassName="peer block w-full p-2 placeholder-transparent font-main text-slate-400 text-xs bg-slate-50 border border-slate-100 focus:border-slate-400 rounded-md focus:outline-none"
              labelClassName="absolute pointer-events-none font-main text-slate-400 text-sm -top-5 left-0 transition-all peer-focus:-top-5 peer-focus:left-0 peer-focus:text-xs peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-2 peer-placeholder-shown:text-sm"
              errorClassName="italic font-main text-xs text-red-500 m-1"
              cb={(value: string) => {
                switch (value.length) {
                  case 0:
                  case 1:
                  case 2: {
                    setActiveTab(Tabs.TODAY);
                    break;
                  }
                  default:
                    setActiveTab(Tabs.SEARCH);
                    break;
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-row space-x-4">
          <Link
            to="/patients/new"
            className="bg-sky-600 rounded-lg py-2 px-3 hover:bg-sky-700"
          >
            <span className="text-white">+ Nouveau patient</span>
          </Link>
        </div>
      </div>

      {patientsBlock}
    </div>
  );
};

export default PatientsPage;
