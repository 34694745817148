import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import { BiCheckDouble } from 'react-icons/bi';

const Acknowledgement: React.FC<{ ack: number }> = ({ ack }) => {
  switch (ack) {
    case 0:
    case 1:
      return <IoCheckmark size={20} />;
    case 2:
      return <BiCheckDouble size={20} className="text-gray-400" />;
    case 3:
      return <BiCheckDouble size={20} className="text-blue-500" />;
    default:
      return null;
  }
};

export default Acknowledgement;
