export enum Roles {
  ADMIN = 'admin',
  PRACTITIONER = 'practitioner',
  PATIENT = 'patient',
  CHILD = 'child',
}

export const translateRole = (role: string) => {
  switch (role) {
    case Roles.ADMIN:
      return 'Administrateur';
    case Roles.PRACTITIONER:
      return 'Praticien';
    case Roles.PATIENT:
    case Roles.CHILD:
    default:
      return 'Patient';
  }
};

export const selectInputRoles = [
  {
    id: Roles.PATIENT,
    label: 'Patient',
  },
  {
    id: Roles.CHILD,
    label: 'Enfant',
  },
];

export enum Statuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const selectInputStatuses = [
  {
    id: Statuses.ACTIVE,
    label: 'Actif',
  },
  {
    id: Statuses.INACTIVE,
    label: 'Inactif',
  },
];

export enum Titles {
  Dr = 'Dr',
  Pr = 'Pr',
  M = 'M',
  Mme = 'Mme',
}

export const selectInputTitles = [
  {
    id: Titles.Dr,
    label: Titles.Dr,
  },
  {
    id: Titles.Pr,
    label: Titles.Pr,
  },
  {
    id: Titles.M,
    label: Titles.M,
  },
  {
    id: Titles.Mme,
    label: Titles.Mme,
  },
];
