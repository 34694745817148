import React, { useEffect, useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import omit from 'lodash.omit';
import { useLogoutMutation } from '@@/services/auth';
import { useGetMeQuery, usePatchUserMutation } from '@@/services/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PractitionnerForm, {
  schema,
} from '@@/components/Forms/PractitionnerForm';
import Signature from '@@/components/Signature';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import PrescriptionTemplateSection from '@@/components/PrescriptionTemplateSection';

type MeFormType = Omit<Unpatient.User, 'medicalRecord'>;
const MePage: React.FC = () => {
  const { data: me } = useGetMeQuery();
  const [logout] = useLogoutMutation();
  const [patchUser, { isSuccess }] = usePatchUserMutation();
  const [signature, setSignature] = useState<string | null>(null);

  const form = useForm<MeFormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    (data: MeFormType) => {
      patchUser(data);
    },
    [patchUser],
  );

  const button = useMemo(() => {
    return (
      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className="my-6 bg-cyan-400 rounded-lg p-2"
      >
        <span className="text-white ml-1">Valider</span>
      </button>
    );
  }, [form, onSubmit]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Vos modifications sont prises en compte`, {
        position: 'top-right',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (me) {
      const data = omit(me, [
        'role',
        '_id',
        'isDeleted',
        'createdAt',
        'updatedAt',
        'medicalRecord',
      ]);

      form.reset(data);
      setSignature(me.signatureDocumentId || null);
    }
  }, [me, form.reset, setSignature]);

  const SignatureBlock = useMemo(() => {
    if (!me?.id) {
      return null;
    }

    if (signature) {
      return (
        <div>
          <AuthenticatedImage
            documentId={signature}
            alt="signature"
            className="border border-gray-200 w-[300px] h-[200px]"
          />
          <button
            type="button"
            onClick={() => {
              setSignature(null);
            }}
            className="my-2 bg-cyan-400 rounded-lg p-2"
          >
            <span className="text-white ml-1">Refaire votre signature</span>
          </button>
        </div>
      );
    }

    return <Signature id={me.id} />;
  }, [me?.id, signature, setSignature]);

  return (
    <div className="relative p-4 w-full">
      <div className="fixed z-10 right-6">{button}</div>
      <PractitionnerForm form={form} />

      <div className="mt-12">
        <p className="font-main text-main">Signature : </p>
        {SignatureBlock}
      </div>

      <PrescriptionTemplateSection />

      <button
        onClick={() => logout()}
        className="p-2 rounded-lg bg-gray-500 mt-4 block ml-auto"
      >
        <p className="font-main text-white">Déconnexion</p>
      </button>

      <div className="h-10" />
    </div>
  );
};

export default MePage;
