import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetMeQuery, useGetPopulatedPatientQuery } from '@@/services/user';
import { useGetChatsStatusQuery } from '@@/services/whatsapp';
import { Roles } from '@@/constants/user';
import { Statuses } from '@@/constants/whatsapp';

import {
  LuSettings,
  LuCalendar,
  LuCheckCheck,
  LuUsers,
  LuBriefcaseMedical,
  LuMessagesSquare,
} from 'react-icons/lu';

import Section from '@@/components/Sidebar/Section';
import Footer from '@@/components/Sidebar/Footer';

const Sidebar: React.FC = () => {
  const { userId, patientId } = useParams();
  const { data: me } = useGetMeQuery();
  const { data: wsStatus } = useGetChatsStatusQuery();
  const { data: patient } = useGetPopulatedPatientQuery(
    patientId || userId || '',
    {
      skip: !patientId && !userId,
    },
  );

  const phone = useMemo(() => patient?.wsPhone || patient?.phone, [patient]);

  const adminSubSections = useMemo(() => {
    const subSections = [
      {
        title: 'Msgs types',
        to: '/admin/template-messages',
      },
      {
        title: 'Msgs programmés',
        to: '/admin/scheduled-messages',
      },
      { title: 'FAXs', to: '/admin/faxes' },
    ];

    if (me?.role === Roles.ADMIN) {
      subSections.push({
        title: 'Métriques',
        to: '/admin/metrics',
      });
    }

    return subSections;
  }, [me?.role]);

  return (
    <div className="w-[190px] relative">
      <div className="bg-white flex flex-col justify-between h-full fixed">
        <div className="flex flex-col">
          <Link to="/home" className="mt-7 mb-4 ml-3">
            <img src="/logo-blue.svg" className="w-30" />
          </Link>
          <Section Icon={LuUsers} title="Patients" to="/patients" />
          <Section
            Icon={LuCalendar}
            title="Calendrier"
            to="/calendar"
            subSections={[
              { title: 'Evènements', to: '/calendar/events' },
              { title: 'Doctolib', to: '/calendar/doctolib' },
            ]}
          />
          <Section
            Icon={LuMessagesSquare}
            title="Conversations"
            to={phone ? `/conversations/c.us/${phone}` : `/conversations`}
            isError={wsStatus && wsStatus.status !== Statuses.READY}
          />
          <Section
            Icon={LuBriefcaseMedical}
            title="Contacts"
            to="/practitioner-contacts"
          />
          <Section Icon={LuCheckCheck} title="Tâches" to="/tasks" />
          <Section
            Icon={LuSettings}
            title="Administration"
            to="/admin"
            subSections={adminSubSections}
          />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Sidebar;
