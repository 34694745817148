import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useGetEventsQuery } from '@@/services/event';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: events } = useGetEventsQuery();

  return (
    <Calendar
      className="m-4"
      localizer={localizer}
      events={events?.map((event) => ({
        title: event.userId?.firstName
          ? `[${event.userId.firstName.slice(0, 1)}. ${event.userId.lastName}] - ${event.name}`
          : event.name,
        start: moment(event.startDate).toDate(),
        end: moment(event.endDate || event.startDate).toDate(),
        userId: event.userId,
        url: event.url,
      }))}
      startAccessor="start"
      endAccessor="end"
      defaultView="agenda"
      messages={{
        week: 'Semaine',
        work_week: 'Semaine de travail',
        day: 'Jour',
        month: 'Mois',
        previous: 'Antérieur',
        next: 'Prochain',
        today: `Aujourd'hui`,
        agenda: 'Ordre du jour',
        showMore: (total) => `+${total} plus`,
      }}
      onSelectEvent={(event) => {
        if (event.url) {
          window.open(event.url, 'newtab');
        } else {
          const phone = event.userId.wsPhone || event.userId.phone;
          const patientId = event.userId._id;
          const url = phone
            ? `/conversations/c.us/${phone}/${patientId}`
            : `/patients/${patientId}`;

          navigate(url);
        }
      }}
    />
  );
};

export default CalendarPage;
