import React, { useRef, useEffect } from 'react';
import cx from 'classnames';

const EditableCell: React.FC<any> = ({ getValue, row, column, table }) => {
  const initialValue = getValue() || column.columnDef.meta?.defaultValue || '';

  const inputRef = useRef<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = initialValue;
    }
  }, [initialValue]);

  const onBlur = () => {
    const currentValue = inputRef.current?.value;
    if (currentValue !== undefined) {
      table.options.meta?.updateData(
        table.getRow(row.index).original.id,
        column.id,
        currentValue,
      );
    }
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    table.options.meta?.updateData(
      table.getRow(row.index).original.id,
      column.id,
      e.target.value,
    );
  };

  if (column.columnDef.meta?.type === 'select') {
    const customClassName = column.columnDef.meta.getClassName
      ? column.columnDef.meta.getClassName(table.getRow(row.index).original)
      : '';

    return (
      <select
        ref={inputRef as React.RefObject<HTMLSelectElement>}
        key={table.getRow(row.index).original.id}
        onChange={onSelectChange}
        defaultValue={initialValue}
        className={cx(
          customClassName || 'bg-transparent text-sm',
          column.columnDef.meta?.width || 'w-18',
        )}
      >
        {column.columnDef.meta?.options?.map(
          (option: { id: string; label: string }) => (
            <option key={option.id} value={option.id} className="text-sm">
              {option.label}
            </option>
          ),
        )}
      </select>
    );
  }

  if (column.columnDef.meta?.type === 'checkbox') {
    return (
      <input
        ref={inputRef as React.RefObject<HTMLInputElement>}
        key={table.getRow(row.index).original.id}
        className={cx(
          'bg-transparent text-center text-sm mx-1 my-0.5',
          column.columnDef.meta?.width || 'w-12',
        )}
        defaultValue={initialValue}
        onChange={onBlur}
        type={column.columnDef.meta?.type || 'text'}
      />
    );
  }

  if (column.columnDef.meta?.type === 'textarea') {
    return (
      <textarea
        ref={inputRef as React.RefObject<HTMLTextAreaElement>}
        key={table.getRow(row.index).original.id}
        className={cx(
          'bg-slate-50 text-xs rounded-lg p-0.5 mx-2',
          column.columnDef.meta?.width || 'w-12',
        )}
        defaultValue={initialValue}
        onBlur={onBlur}
        rows={column.columnDef.meta?.rows || 3}
      />
    );
  }

  return (
    <input
      ref={inputRef as React.RefObject<HTMLInputElement>}
      key={table.getRow(row.index).original.id}
      className={cx(
        'bg-transparent text-center text-sm mx-1 my-0.5',
        column.columnDef.meta?.width || 'w-12',
      )}
      defaultValue={initialValue}
      onBlur={onBlur}
      type={column.columnDef.meta?.type || 'text'}
    />
  );
};

export default EditableCell;
